import React, { useState, useEffect } from 'react';
import { Link } from 'gatsby';
import { Layout, SEO } from '../components';
import { ReactComponent as CloseIcon } from '@src/assets/icons/close.svg';
import { useDispatch, useSelector } from 'react-redux';
import { removeFromCart, clearCart, bulkRemoveFromCart } from '@src/store/cart';
import { gql, useLazyQuery } from '@apollo/client';
import { login } from '@src/utils/mmc-api/auth';
import * as api from '@src/utils/mmc-api/api';
import SittingFrog from '../assets/images/frog-sitting.webp';

import Spinner from '../components/UI/Spinner';

const DOCUMENT_QUERY = gql`
    query Documents($ids: [ItemId]) {
        allDocuments(filter: { id: { in: $ids } }) {
            id
            isSubscription
        }
    }
`

const Cart = ({ location }) => {
  const [content, setContent] = useState([]);
  const [newContent, setNewContent] = useState([]);
  const [totalPrice, setTotalPrice] = useState(0);
  const [message, setMessage] = useState('');
  const user = useSelector((state) => state.user.data);
  const miniCart = useSelector((state) => state.cart.miniCart);
  const [isLoading, setIsLoading] = useState(true);
  let cart;
  const dispatch = useDispatch();

  const [getDocuments, { data, error }] = useLazyQuery(DOCUMENT_QUERY);

  const slugify = (text) =>
    text
      .toString()
      .toLowerCase()
      .replace(/\s+/g, '-') // Replace spaces with -
      .replace(/[^\w\-]+/g, '') // Remove all non-word chars
      .replace(/\-\-+/g, '-') // Replace multiple - with single -
      .replace(/^-+/, '') // Trim - from start of text
      .replace(/-+$/, ''); // Trim - from end of text

  async function fetchFullCart() {
    if (user && user?.email) {
      cart = await api.fullCart();
    } else {
      cart = await api.guestCart(miniCart);
    }

    setContent(cart?.content || []);
    setTotalPrice(cart?.totalPrice || 0);
    setIsLoading(false);
  }

  useEffect(() => {
    if (content.length > 0) {
      content.sort((a, b) => a.module.slug.localeCompare(b.module.slug));
    }
  }, [content]);

  async function removeFromCartButtonClick(event, id) {
    event.preventDefault();

    await dispatch(removeFromCart(id, user));
    fetchFullCart();
  }

  async function clearCartButtonClick(event) {
    event.preventDefault();

    await dispatch(clearCart());
    fetchFullCart();
  }

  useEffect(() => {
    fetchFullCart();
  }, [user, miniCart]);

  useEffect(() => {
    if (miniCart) {
        getDocuments({ variables: { ids: miniCart }});
    }
  }, [miniCart]);

  useEffect(() => {
    if (data && user?.subscribed) {
        const subscriptionDocuments = data.allDocuments.filter(({ isSubscription }) => isSubscription);
        const subscriptionIds = subscriptionDocuments.map(({ id }) => id);
        if (!subscriptionIds?.length) return;
        setMessage('We have noticed some documents are part of your subscription. We removed them from your cart. Enjoy your new content!');
        dispatch(bulkRemoveFromCart(subscriptionIds, user));
    }
  }, [data, user]);

  let module;
  let title;
  let category;
  let subTitle;

useEffect(()=>{
  const dynamicSort = (property) => {
    var sortOrder = 1;
    if(property[0] === "-") {
        sortOrder = -1;
        property = property.substr(1);
    }
    return function (a,b) {
        /* next line works with strings and numbers, 
         * and you may want to customize it to your needs
         */
        var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
        return result * sortOrder;
    }
  }
  
  const dynamicSortMultiple = () => {
    /*
     * save the arguments object as it will be overwritten
     * note that arguments object is an array-like object
     * consisting of the names of the properties to sort by
     */
    var props = arguments;
    return function (obj1, obj2) {
        var i = 0, result = 0, numberOfProperties = props.length;
        /* try getting a different result from 0 (equal)
         * as long as we have extra properties to compare
         */
        while(result === 0 && i < numberOfProperties) {
            result = dynamicSort(props[i])(obj1, obj2);
            i++;
        }
        return result;
    }
  }

  setNewContent(content.sort(dynamicSortMultiple("module?.slug", "name")))
},[content])


  const renderModuleTitle = (module, title) => {
    return (
      <tr className={title ? 'border-t' : ''}>
        <th className={title ? 'cart-title' : 'cart-subtitle'}>{module}</th>
        <th></th>
        <th></th>
      </tr>
    );
  };

  return (
    <Layout>
      <SEO title="Cart" noIndex={true} />
      {isLoading ? (
        <Spinner />
      ) : (
        <div
          className={
            !!newContent.length && user?.email
              ? 'cart-page-container mx-auto p-1'
              : 'cart-page-container-no-user mx-auto p-1'
          }
        >
          <article
            className={
              !!newContent.length && user?.email
                ? 'text-gray-700 full-width'
                : 'cart-page-no-user text-gray-700'
            }
          >
            <div className="mb-1">
                {message && (
                    <div className="text-2xl text-blue-500">{message}</div>
                )}
            </div>
            <div className="flex justify-between mb-1">
              <h2 className="text-2xl text-blue-500">Your cart</h2>
              {!!newContent.length && (
                <a
                  href="#"
                  className="mt-auto text-blue-500"
                  onClick={(event) => clearCartButtonClick(event)}
                >
                  Remove all
                </a>
              )}
            </div>

            <div class="blue-line-divider">
                <img src={SittingFrog} />
            </div>

            {!!newContent.length && (
              <>
                <table className="w-full mb-3">
                  <tbody>
                    {!!newContent.length &&
                      newContent.map((item) => {
                        title = undefined;
                        subTitle = undefined;
                        if (module !== item.module.slug) {
                          category = undefined;
                          module = item.module.slug;
                          title = renderModuleTitle(item.module.title, true);
                        }

                        if (item.documentCategory[0]?.name && category !== item.documentCategory[0]?.name) {
                          category = item.documentCategory[0]?.name;
                          subTitle = renderModuleTitle(item.documentCategory[0]?.name, false);
                        }

                        return (
                          <>
                            {title ? title : ''}
                            {subTitle ? subTitle : ''}
                            <tr key={item.id}>
                              <td className="cart-item">
                                <a
                                  href={`/modules/${item.module.slug}/${item.documentCategory
                                    .map((c) => slugify(c.name))
                                    .join('/')}/${encodeURIComponent(slugify(item.name))}`}
                                >
                                  {item.name}
                                </a>
                              </td>
                              <td className="cart-item text-center">£{item.price}</td>
                              <td>
                                <a
                                  href="#"
                                  onClick={(event) => removeFromCartButtonClick(event, item.id)}
                                >
                                  <CloseIcon className="w-4 h-4 cart-remove-item" />
                                </a>
                              </td>
                            </tr>
                          </>
                        );
                      })}
                  </tbody>
                </table>

                <p className="text-xl text-center mb-3">
                  <span className="p-1 border-b font-bold">Total</span>
                  <span className="p-1 border-b ">£{totalPrice}</span>
                </p>

                <p className="text-xl text-center mb-4">
                  {user?.email ? (
                    <Link to="/checkout" className="button w-button">
                      Pay
                    </Link>
                  ) : (
                    <button className="button w-button" onClick={login}>
                      Login / Create account
                    </button>
                  )}
                </p>
              </>
            )}

            {!newContent.length && <p className="mt-2">Your cart is empty</p>}
          </article>
        </div>
      )}
    </Layout>
  );
};

export default Cart;
